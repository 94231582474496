/*
  Authors : NexHut (Wassim Dossani)
  Website : https://NexHut.com/
  App Name : Quickerz
  Created : 28-Feb-2021
  This App Template Source code is licensed as per the
  terms found in the Website https://NexHut.com/license
  Copyright © 2021-present NexHut.
*/
import { Directive, HostListener, Inject } from '@angular/core';

import { AccordionLinkDirective } from './accordionlink.directive';

@Directive({
  selector: '[appAccordionToggle]'
})
export class AccordionAnchorDirective {

  protected navlink: AccordionLinkDirective;

  constructor(@Inject(AccordionLinkDirective) navlink: AccordionLinkDirective) {
    this.navlink = navlink;
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    this.navlink.toggle();
  }
}
