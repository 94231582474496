// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://api.quickerz.com/index.php/',
  mediaURL: 'https://api.quickerz.com/uploads/',
  onesignal: {
    appId: 'c7c68d2c-b89e-42d4-8aef-08146f0fe500',
    googleProjectNumber: '435952310338',
    restKey: 'MmFlNjg3MDMtYmRlMi00NmQ2LWIzNDAtMDU1OWUwZDY5YmFl'
  },
  general: {
    symbol: '$',
    code: 'USD'
  },
  authToken: '8329296039',
  default_country_code: '1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
