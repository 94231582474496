/*
  Authors : NexHut (Wassim Dossani)
  Website : https://NexHut.com/
  App Name : Quickerz
  Created : 28-Feb-2021
  This App Template Source code is licensed as per the
  terms found in the Website https://NexHut.com/license
  Copyright © 2021-present NexHut.
*/
import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[cardRefresh]'
})
export class CardRefreshDirective {

    constructor(private el: ElementRef) { }
    @HostListener('mouseenter') open() {
        this.el.nativeElement.classList.add('rotate-refresh');
    }

    @HostListener('mouseleave') close() {
        this.el.nativeElement.classList.remove('rotate-refresh');
    }
}