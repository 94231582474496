/*
  Authors : NexHut (Wassim Dossani)
  Website : https://NexHut.com/
  App Name : Quickerz
  Created : 28-Feb-2021
  This App Template Source code is licensed as per the
  terms found in the Website https://NexHut.com/license
  Copyright © 2021-present NexHut.
*/
import { ElementRef, Directive, HostListener } from "@angular/core";
@Directive({
    selector: '[parentRemove]'
})
export class ParentRemoveDirective {
    alert_parent: any;
    constructor(private elements: ElementRef) { }

    @HostListener('click', ['$event'])
    onToggle($event: any) {
        $event.preventDefault();
        this.alert_parent = (this.elements).nativeElement.parentElement;
        this.alert_parent.remove();
    }
}